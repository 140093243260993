<template>
    <div class="serviceArea">
        <h2 class="title-page">服务区域</h2>
        <div class="content-station">
            <!-- 顶部 -->
            <div class="area-flex area-info">
                <!-- <div class="staff-flex">
                    <label class="select-label">品牌</label>
                    <el-select v-model="checkBrand" placeholder="请选择" @change="getCompanyList">
                        <el-option
                            v-for="item in brandList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <div class="staff-flex">
                    <label class="select-label">门店</label>
                    <el-select v-model="checkCompany" placeholder="请选择">
                        <el-option
                            v-for="item in companyList"
                            :key="item.id"
                            :label="item.alias_store_name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div> -->
                <div class="staff-flex">
                    <label class="select-label">切换岗位</label>
                    <el-select v-model="checkStation" placeholder="请选择" @change="selectChange">
                        <el-option
                            v-for="item in stationList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        ></el-option>
                    </el-select>
                </div>
                <el-button type="primary" @click="toAreaTemp">临时调整服务区域</el-button>
            </div>

            <!-- 员工日常服务区域 -->
            <p class="service-yg">员工日常服务区域</p>
            <div class="area-div">
                <div class="list-div" v-for="(item,index) in useAreaList" :key="index">
                    <div class="title-area">{{ item.name }}</div>
                    <div class="rw-info">{{ item.user_name }}</div>
                </div>
                <div class="list-div" v-if="useAreaList.length==0">
                    <div class="title-area">暂无服务区域</div>
                    <div class="rw-info">暂无服务人员</div>
                </div>
            </div>

            <!-- 员工临时服务区域 -->
            <p class="service-yg">员工临时服务区域</p>
            <div v-for="(item,index) in useAreaTempList" :key="index">
                <div class="time-div">
                    <font>{{ item.date }}</font>
                    <i class="el-icon-delete" @click="delDd(item)"></i>
                </div>
                <div class="area-div">
                    <div class="list-div" v-for="(citem,cindex) in item.area_data" :key="cindex">
                        <div class="title-area">{{ citem.name }}</div>
                        <div class="rw-info">{{ citem.user_name }}</div>
                    </div>
                </div>
            </div>
            <div class="list-div no-data" v-if="useAreaTempList.length==0">
                <div class="title-area">暂无临时服务区域</div>
                <div class="rw-info">暂无临时服务人员</div>
            </div>
        </div>
        <!-- 新增临时调度区域 -->
        <el-dialog :title="checkStationName" :visible.sync="addDialog" width="680px">
            <div>
                <span>临时调整区域</span>
                <el-date-picker
                    value-format="yyyy-MM-dd"
                    v-model="times"
                    type="daterange"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </div>
            <div class="checks-div">
                <div class="area-flex yg-infos">
                    <span class="tag-left">员工姓名</span>
                    <span class="flex1">服务区域</span>
                </div>
                <div class="area-flex yg-infos" v-for="(item,index) in checkList" :key="index">
                    <span class="tag-left">{{ item.name }}</span>
                    <el-checkbox-group v-model="item.checksGroups" class="flex1">
                        <el-checkbox v-for="(citem,cindex) in item.groups" :key="cindex" :label="citem.id">
                            {{ citem.name }}
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div slot="footer">
                <el-button @click="addDialog=false" class="close-btn">取 消</el-button>
                <el-button type="primary" @click="addQeury">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    areaStationUser,
    areaTempList, delAreaTemp, getUserAreaList,
    selectBrandList,
    selectCompanyList,
    selectStationList, updateAreaTemp,
} from "../../../api/srv-store";

export default {
    name: 'serviceArea',
    data() {
        return {
            checkBrand: '',
            checkCompany: '',
            checkStation: '',
            checkStationName: '',
            brandList: [],
            companyList: [],
            stationList: [],
            useAreaList: [],
            useAreaTempList: [],
            addDialog: false,
            times: '',
            checkList: [],
            pickerOptions: {
                disabledDate: (time) => {
                    return time.getTime() < new Date().getTime() - 1000*60*60*24
                }
            }
        }
    },
    created() {
        // this.getBrandList()
        this.checkBrand = JSON.parse(localStorage.getItem('brandId'))
        this.checkCompany = JSON.parse(localStorage.getItem('storesId'))
        this.getStationList()
        this.getUserAreaList()
        this.getUserTempAreaList()
    },
    methods: {
        //获取品牌列表
        getBrandList() {
            selectBrandList().then(Er => {
                if (Er.return_code === 200) {
                    this.brandList = Er.data
                    this.checkBrand = localStorage.getItem('brand_id') != 0 ? parseInt(localStorage.getItem('brand_id')) : this.brandList[0].id
                    this.getCompanyList()
                }
            })
        },

        //获取门店列表
        getCompanyList() {
            selectCompanyList({
                brand_id: this.checkBrand
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.companyList = Er.data
                    this.checkCompany = this.companyList[0].id
                    this.getStationList()
                    this.getUserAreaList()
                    this.getUserTempAreaList()
                }
            })
        },

        //获取岗位列表
        getStationList() {
            selectStationList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany
            }).then(Er => {
                if (Er.return_code === 200) {
                    let arr = Er.data;
                    arr.unshift({
                        id: '',
                        name: "请选择"
                    })
                    this.stationList = arr;
                }
            })
        },

        //获取员工服务区域列表
        getUserAreaList() {
            let _this = this
            areaStationUser({
                brand_id: _this.checkBrand,
                store_id: _this.checkCompany,
                station_id: _this.checkStation,
            }).then(Er => {
                if (Er.return_code === 200) {
                    Er.data.map(item => {
                        let newObj = [];
                        if (item.area_user.length > 0) {
                            item.area_user.map(citem => {
                                newObj.push(citem.name)
                            })
                            item.user_name = newObj.join('、')
                        }
                    })
                    _this.useAreaList = Er.data
                }
            })
        },

        //获取员工临时服务区域列表
        getUserTempAreaList() {
            let _this = this
            areaTempList({
                brand_id: _this.checkBrand,
                store_id: _this.checkCompany,
                station_id: _this.checkStation,
            }).then(Er => {
                if (Er.return_code === 200) {
                    Er.data.map(item => {
                        if (item.area_data.length > 0) {
                            item.area_data.map(citem => {
                                if (citem.area_temp_user.length > 0) {
                                    let newObj = [];
                                    citem.area_temp_user.map(uitem => {
                                        newObj.push(uitem.name)
                                    })
                                    citem.user_name = newObj.join('、')
                                }
                            })
                        }
                    })
                    _this.useAreaTempList = Er.data
                }
            })
        },

        toAreaTemp() {
            if (!this.checkStation) {
                this.$message({
                    type: 'warning',
                    message: '请选择岗位'
                });
                return false
            }
            //获取调整员工临时区域页面信息
            getUserAreaList({
                brand_id: this.checkBrand,
                store_id: this.checkCompany,
                station_id: this.checkStation,
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.checkList = Er.data
                }
            })
            this.addDialog = true
        },

        //select选择器
        selectChange: function () {
            this.stationList.map(item => {
                if (item.id === this.checkStation) {
                    this.checkStationName = item.name
                }
            })
            this.getUserAreaList()
            this.getUserTempAreaList()
        },

        addQeury: function () {
            if(this.times.length!==2){
                this.$message('请选择临时调整日期')
                return false;
            }
            let begin_date = this.times[0];
            let end_date = this.times[1];
            let detail = [], ischeck=false;
            this.checkList.map(item => {
                let newArr = {};
                newArr.user_id = item.user_id
                newArr.area_id = item.checksGroups
                detail.push(newArr)
                if(item.checksGroups.length>0){
                    ischeck = true;
                }
            })

            if(!ischeck){
                this.$message('请选择服务区域')
                return false;
            }

            //保存员工临时调整区域信息
            updateAreaTemp({
                brand_id: this.checkBrand,
                store_id: this.checkCompany,
                station_id: this.checkStation,
                begin_date: begin_date,
                end_date: end_date,
                detail: JSON.stringify(detail),
            }).then(Er => {
                if (Er.return_code === 200) {
                    this.$message({
                        type: 'success',
                        message: Er.return_msg
                    });
                    this.addDialog = false
                    this.getUserTempAreaList()
                }
            })
        },

        //删除临时调度
        delDd: function (val) {
            let newObj = [];
            val.area_data.map(item => {
                if (item.area_temp_user.length > 0) {
                    item.area_temp_user.map(citem => {
                        newObj.push(citem.id)
                    })
                }
            })
            this.$confirm('是否删除', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                delAreaTemp({
                    temp_ids: newObj.join(','),
                    brand_id: this.checkBrand,
                    store_id: this.checkCompany,
                }).then(Er => {
                    if (Er.return_code === 200) {
                        this.$message({
                            type: 'success',
                            message: Er.return_msg
                        });
                        this.addDialog = false
                        this.getUserTempAreaList()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        }
    }
}
</script>
<style scoped>
.serviceArea {
    padding-bottom: 40px;
}

.title-page {
    font-size: 30px;
    color: #3e566e;
    padding-top: 40px;
    padding-bottom: 30px;
}

.area-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.area-info {
    padding: 40px 0;
}

.el-select {
    margin-left: 20px;
}

.content-station {
    padding: 0 40px;
    background-color: #ffffff;
    box-shadow: 0px 10px 20px 0px rgba(62, 86, 110, 0.06);
    border-radius: 6px;
    overflow: auto;
}

.service-yg {
    font-size: 24px;
    color: #3E566E;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
}

.area-div {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0 50px 0;
    border-radius: 6px;
    border: 1px solid rgba(62, 86, 110, 0.2);
}

.list-div {
    display: inline-block;
    flex: 1;
    min-width: 20%;
    text-align: center;
    font-size: 14px;
    color: #3E566E;
}

.title-area {
    color: rgba(62, 86, 110, 0.6);
    line-height: 60px;
    background-color: rgba(175, 192, 207, 0.2);
}

.rw-info {
    width: 100%;
    padding: 20px 30px;
    box-sizing: border-box;
    white-space: normal;
}

.time-div {
    margin: 20px 0;
    font-size: 16px;
    color: #DC2718;
}

.el-icon-delete {
    color: #148BFF;
    margin-left: 20px;
}

.serviceArea /deep/ .el-date-editor {
    margin-left: 10px;
}

.yg-infos {
    margin-bottom: 20px;
}

.flex1 {
    flex: 1;
}

.checks-div {
    height: 334px;
    margin-top: 20px;
    padding-top: 20px;
    padding-left: 30px;
    border-radius: 6px;
    border: 1px solid rgba(62, 86, 110, 0.2);
    box-sizing: border-box;
}

.tag-left {
    width: 180px;
}
.no-data{
    margin-top: 20px;
    width: 100%;
}
</style>